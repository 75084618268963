import * as React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Seo from "../components/seo"

const RegistrationPage = () => (
  <Layout>
    <>
      <div class="row page-row">
        <div class="col-12 col-lg-8">
          <div class="page-header">
            <hr />
            <h1>
              <strong>Register</strong>
            </h1>
          </div>

          <div class="entry-content conference-max mt-3">
            <div id="EqNlAELjmn">
              <Helmet>
                <script
                  src="https://default.salsalabs.org/api/widget/template/501cc688-83d0-45e2-876b-24d429b6f03b/?page=register&tId=EqNlAELjmn"
                  type="text/javascript"
                ></script>
              </Helmet>
            </div>
          </div>
        </div>
      </div>
    </>
  </Layout>
)

export default RegistrationPage
